#demo {
  .row {
    margin-bottom: 100px;
    background-color: #ffffff;
    box-shadow: $box-shadow;
    padding: 40px 0 46px;

    &.top {
      text-align: center;
      padding: 40px 24px 46px;

      .title_box {
        margin-bottom: 45px;
        text-align: center;
        color: $primary-purple;
        line-height: 30px;

        img {
          width: 114px;
          margin: 0 auto 37px;
          display: block;
        }

        p {
          font-size: 30px;
          font-weight: bold;
        }
      }

      .main_box {
        span {
          font-size: 20px;
          display: block;

          &.title {
            margin-bottom: 25px;
          }
        }

        p {
          margin-top: 15px;
        }

        .purple_button {
          max-width: 164px;
          padding: 12px 15px;
          line-height: 1.5;
          margin-top: 40px;
        }
      }
    }

    &.bottom {
      text-align: left;
      padding: 28px 48px 45px;

      strong {
        font-size: 24px;
        font-weight: 600;
        line-height: 1.42;
        color: $primary-purple;
      }

      p {
        font-size: 16px;
        font-weight: 200;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        color: $primary-purple;
        margin-top: 16px;
        margin-bottom: 32px;
      }

      ul {
        li {
          width: 140px;
          margin-right: 88px;
          margin-bottom: 40px;
          display: inline-block;

          .icon {
            margin-bottom: 35px;

            > img {
              width: 70px;
              height: 70px;
              object-fit: contain;
            }
          }

          span {
            font-weight: 600;
            line-height: 1;
            color: $primary-purple;
            text-align: left;
          }

          img {
            margin-left: 12px;
            width: 24px;
          }
        }
      }
    }
  }
}

@media (max-width: 550px) {
  #demo .row {
    margin-bottom: 24px;

    &.top {
      padding: 40px 20px 40px;

      .title_box {
        margin-bottom: 30px;

        img {
          width: 61px;
        }

        p {
          font-size: 16px;
          margin-bottom: 0px;
        }
      }
      .main_box {
        span {
          font-size: 16px;

          &.title {
            margin-bottom: 15px;
          }
        }

        p {
          font-size: 12px;
        }
      }
    }

    &.bottom {
      padding: 30px 24px 36px;
      strong {
        font-size: 20px;
      }

      p {
        font-size: 16px;
      }

      ul li {
        width: calc(50% - 2.5px);
        margin-right: 5px;

        &:nth-child(even) {
          margin-right: 0;
        }
      }
    }
  }
}

@media (max-width: 370px) {
  #demo .row.bottom ul li {
    margin: 0 0 40px;
    width: 100%;
    text-align: center;

    .icon {
      > img {
        width: 40px;
        height: 40px;
      }
    }
  }
}
